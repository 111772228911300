/** @jsx jsx */
import { gql } from '@apollo/client';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import ToggleFavorite from '@bottlebooks/gatsby-theme-event/src/components/Favorites/ToggleFavorite';
import useFavorites from '@bottlebooks/gatsby-theme-event/src/components/Favorites/useFavorites';
import {
  hasDifferentExhibitor,
  ProductHeaderDetails,
  ProductHeaderDetailsFragment,
  ProductName,
  ProductNameFragment,
  ProductProducer,
  ProductProducerNameFragment,
  ProductStand,
  ProductStandFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImageCol, {
  ProductImageFragment_large,
} from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductImageCol';
import ProductNavigation from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductNavigation';
import {
  ProductMainCol,
  ProductPageHeaderRow,
} from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductPageHeader';
import ProductNumber from '../../../../components/ProductNumber';

export default function EventProductPageHeader({
  product,
  next,
  previous,
  standPath,
  overlapImage,
}) {
  const link = useLink();
  const { isFavorite, toggleFavorite } = useFavorites();
  const hasOtherExhibitor = hasDifferentExhibitor(product);
  return (
    <ProductPageHeaderRow sx={{ justifyContent: 'center' }}>
      <ProductImageCol product={product} overlap={overlapImage} />
      <ProductMainCol>
        <ProductStand
          stand={product?.stand}
          exhibitor={hasOtherExhibitor && product?.exhibitor}
          to={hasOtherExhibitor && standPath}
        />
        <ProductProducer
          producer={product?.producer}
          to={link.producer(product.producer)}
        />
        <ProductNumber product={product} />
        <ProductName as="h1" product={product} />
        <ProductHeaderDetails product={product} />
        <ToggleFavorite
          sx={{ marginTop: 3, marginLeft: -2, padding: 0, paddingRight: 3 }}
          isToggled={isFavorite(product?.productId)}
          onClick={() =>
            toggleFavorite(product?.productId, {
              type: 'product',
              name: product.fullName,
            })
          }
        />
      </ProductMainCol>

      <ProductNavigation previous={previous} next={next} />
    </ProductPageHeaderRow>
  );
}

export const fragment = graphql`
  fragment bb_EventProductPageHeader on Bottlebooks_Product {
    productId
    ...bb_ProductHeaderDetails
    producer {
      ...useLink_bb_Producer
    }
  }

  fragment EventProductPageHeader on Product {
    ...ProductImageLarge
    ...ProductStand
    ...ProductProducerName
    ...ProductName
    ...ProductHeaderDetails
  }
`;

export const EventProductPageHeaderFragment = gql`
  fragment EventProductPageHeader on Product {
    productId
    ...ProductImageLarge
    ...ProductStand
    ...ProductProducerName
    ...ProductName
    ...ProductHeaderDetails
  }

  ${ProductImageFragment_large}
  ${ProductStandFragment}
  ${ProductProducerNameFragment}
  ${ProductNameFragment}
  ${ProductHeaderDetailsFragment}
`;
