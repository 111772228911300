/** @jsx jsx */
import { gql } from '@apollo/client';
import ProductShopButton from '@bottlebooks/gatsby-theme-event/src/components/Product//ProductActions/ProductShopButton';
import ProductInfoSheetButton from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductActions/ProductInfoSheetButton';
import AddToCartButton from './ProductActions/AddToCartButton';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ProductActions({ product }) {
  return (
    <React.Fragment>
      {/* <ProductShopButton product={product} sx={{ marginRight: 2 }} /> */}
      <AddToCartButton productId={product.productId} sx={{ marginRight: 3 }} />
      <ProductInfoSheetButton product={product} sx={{ marginRight: 2 }} />
    </React.Fragment>
  );
}

export const fragment = graphql`
  fragment bb_ProductActions on Bottlebooks_Product {
    ...bb_ProductShopButton
    ...bb_ProductInfoSheetButton
  }
`;

ProductActions.fragment = gql`
  fragment ProductActions on Product {
    ...ProductShopButton
    ...ProductInfoSheetButton
  }
  ${ProductShopButton.fragment}
  ${ProductInfoSheetButton.fragment}
`;
